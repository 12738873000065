<template>
  <div v-if="hasResource('customer_management')" class="aops-subscritpion">
    <div class="search-form">
        <div class="dis-fix">
          <el-form ref="form" :inline="true" :model="filter" :rules="rules">
            <el-form-item label="用户名称" prop="name">
              <el-input
                v-model="filter.name"
                placeholder="请输入用户名称"
                clearable
                style="width: 152px;"
              ></el-input>
            </el-form-item>
            <el-form-item label="类型" >
              <el-select v-model="filter.organization_type">
                <el-option
                  v-for="item in options.type"
                  :key="item.label"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="状态" >
              <el-select v-model="filter.status">
                <el-option
                  v-for="item in options.status"
                  :key="item.label"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-form>
          <div class="text-btn">
            <span class="serach-button" @click="search">查询</span>
            <span class="clear-button" @click="cancel">清空</span>
          </div>
        </div>
      </div>
    <el-table
      ref="table"
      v-loading="isTableLoading"
      :data="dataList"
      style="width: 100%;"
      class="data-table"
      header-cell-class-name="table-head"
      cell-class-name="table-cell"
    >
     <el-table-column
        v-for="col in tableColumns"
        :key="col.label"
        :prop="col.prop"
        :label="col.label"
        :width="col.width"
        :min-width="col.minWidth"
        :align="col.align"
        :show-overflow-tooltip="true"
        :fixed="col.fixed">
        <template slot-scope="{row}">
            <div v-if="col.prop=== 'organization_type'">
               {{`${row[col.prop]}`=== '1' ? '公司' : '学校'}}
            </div>
            <div v-else-if="col.prop === 'status'">
                {{`${row[col.prop]}`=== '1' ? '已处理' : '未处理'}}
            </div>
            <div v-else-if="col.prop === 'created_at'">
            {{setTime(row.created_at)}}
            </div>
            <div v-else-if="col.prop === 'operation'" class="icon-btn">
                <div class="icon-btn">
                    <el-button
                        size="mini"
                        type="text"
                        @click="toDetail(row)" >
                        <i class="iconfont">&#xe662;</i>查看
                    </el-button>
                    <el-button
                        v-if="!row.status"
                        size="mini" type="text"
                        @click="handleItem(row)">
                            <i class="iconfont">&#xe65c;</i>处理
                    </el-button>
                </div>
            </div>
            <div v-else>{{row[col.prop] || '--'}}</div>
        </template>
     </el-table-column>
    </el-table>
    <div class="aop-pagination">
      <el-pagination
        v-if="total != 0"
        :current-page.sync="currentPage"
        :page-sizes="[10, 20, 50]"
        :page-size="pagesize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurPageChange"
      ></el-pagination>
    </div>
     <el-dialog
        top="12%"
        :close-on-click-modal="false"
        :visible.sync="visible"
        width="458px"
        class="add-dialog dialogTip">
        <div class="dialog-content">
          <div class="aop-dialog-title">您已处理了该订阅申请？</div>
        </div>
        <span slot="footer" class="dialog-footer pop-dialog">
          <el-button class="confirm-button" :loading="btnLoading"  @click="submit()">确认</el-button>
          <el-button class="cancel-button" @click="visible = false">取 消</el-button>
        </span>
      </el-dialog>
  </div>
</template>

<script>
/* eslint-disable require-jsdoc */
/* eslint-disable max-lines */
import { formatDate } from 'ecoplants-lib';
import api from '../../../api/customer';
import customer from '../../../mixins/customer';

export default {
    mixins: [customer],
    data() {
        return {
            tableColumns: [
                { prop: 'created_at', label: '创建日期', width: 240, align: 'left' },
                { prop: 'name', label: '用户名称', width: 180, align: 'left' },
                { prop: 'email', label: '邮箱', width: 180, align: 'left' },
                { prop: 'mobile', label: '手机号', width: 180, align: 'left' },
                { prop: 'organization_name', label: '名称', width: 200, align: 'left' },
                { prop: 'organization_type', label: '类型', width: 120, align: 'left' },
                { prop: 'status', label: '状态', width: 120, align: 'left' },
                { prop: 'message', label: '内容', minWidth: 200, align: 'left' },
                { prop: 'operation', label: '操作', width: 280, align: 'left', fixed: 'right' }
            ],
            isTableLoading: false,
            options: {
                status: [
                    { label: '全部', value: '' },
                    { label: '已处理', value: 1 },
                    { label: '未处理', value: 0 }
                ],
                type: [
                    { label: '全部', value: '' },
                    { label: '公司', value: 1 },
                    { label: '学校', value: 2 }
                ]
            },
            header: {
                url: 'list'
            },
            filter: {
                name: '',
                status: '',
                organization_type: ''
            },
            visible: false,
            btnLoading: false,
            currentAsset: null,
            dataList: [],
            currentPage: 1,
            pagesize: 10,
            total: 0
        };
    },
    created() {
        this.getCustomerList();
    },
    methods: {
        setTime(val) {
            return formatDate(val * 1000, 'yyyy-MM-dd hh:mm:ss') || '--';
        },
        getCustomerList() {
            this.isTableLoading = true;
            const params = { page_size: this.pagesize,
                page: this.currentPage,
                filter: {
                    ...this.filter
                } };
            api.subscribeList(params).then((res) => {
                if (res.data.code === 10200) {
                    this.dataList = res.data.data.items;
                    this.total = res.data.data.total;
                }
                this.isTableLoading = false;
            });
        },

        handleSizeChange(val) {
            this.pagesize = val;
            this.getCustomerList();
        },

        handleCurPageChange(val) {
            this.currentPage = val;
            this.getCustomerList();
        },

        search() {
            this.currentPage = 1;
            this.getCustomerList();
        },
        cancel() {
            this.currentPage = 1;
            this.pagesize = 10;
            this.filter = {
                name: '',
                organization_type: '',
                status: ''
            };
        },
        toDetail(val) {
            this.$router.push(`/subscribes-detail?id=${val.id}`);
        },
        handleItem(val) {
            this.visible = true;
            this.currentAsset = val;
        },
        submit() {
            const data = {
                id: this.currentAsset.id
            };
            this.btnLoading = true;
            try {
                api.updateSubscribeStatus(data).then((res) => {
                    if (res.data.code === 10200) {
                        setTimeout(() => {
                            this.search();
                            this.$$success('操作成功');
                            this.visible = false;
                            this.btnLoading = false;
                        }, 1500);
                    } else {
                        this.btnLoading = false;
                        this.$$error(res.data.message);
                    }
                });
            } catch (e) {
                this.btnLoading = false;
            }
        }
    }
};
</script>
<style lang="less">
@import "./index.less";
</style>
